<template>
	<div class="show-input-mobile-box  flex  space">
		<div class="cl-info ft12">{{datas.valueName}}</div>
		<div class="cl-main ft14">请选择<a-icon type="right" /></div>
	</div>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return{
				loading:false,
				calldata:null,
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.show-input-mobile-box{
		padding: 16px 15px;
	}
	.show-input-mobile-box-left{
		font-size: 12px;
		color: #8A8D99;
		width: 25%;
	}
	.show-input-mobile-box-right{
		font-size: 14px;
		color: #232529;
		
	}
	
</style>