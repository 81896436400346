<template>
	<div>
		<a-button type="link" @click="showAct"  icon="setting">设置</a-button>
		
		<a-modal
			  width="900px"
		      title="设置input输入框"
		      :visible="showEdit"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="ft14 cl-main">基本设置</div>
			<div class="mt12">
				   默认提示信息 <a-input  v-model="mydatas.parameter.placeholder"  placeholder="请输入默认信息"/>
			</div>
			<div class="mt12">
				   字段名 <a-input  v-model="mydatas.valueName"  placeholder="请输入字段名"/>
			</div>
			<div class="mt12">
				   input标识符(确保在这个表单中是唯一性) <a-input  v-model="mydatas.valueType"  placeholder="请输入input标识符(建议用字段名的英文)"/>
			</div>
			
		</a-modal>
	</div>
</template>

<script>
	export default{
		
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
		},
		watch:{
			mydatas:{
				handler(newName, oldName){
					this.$emit('input',this.mydatas);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.mydatas){
						this.mydatas = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data(){
			return {
				mydatas:null,
				showEdit:false,
			}
		},
		created(){
			this.mydatas = this.value;
		},
		methods:{
			showAct(){
				this.showEdit = true;
			},
			handleCancel(){
				this.showEdit = false;
			},
		}
	}
</script>

<style>
	
</style>