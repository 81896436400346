<template>
	<a-form-model-item  :label="datas.valueName" >
		<a-select style="width:200px" >
		    <a-select-option value="请选择">
		        请选择
		    </a-select-option>
			<a-select-option :value="item.name" v-for="(item,index) in datas.datas" :key="index">
			    {{item.name}}
			</a-select-option>
		</a-select>
	</a-form-model-item>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return{
				loading:false,
				calldata:null,
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	
</style>