<template>
	<div>
		<a-button type="link" @click="showAct"  icon="setting">设置</a-button>
		
		<a-modal
			  width="900px"
		      title="设置switch开关"
		      :visible="showEdit"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="ft14 cl-main">基本设置</div>
			<div class="mt12">
				   字段名 <a-input  v-model="mydatas.valueName"  placeholder="请输入字段名"/>
			</div>
			<div class="mt12">
				   switch选择器标识符(确保在这个表单中是唯一性) <a-input  v-model="mydatas.valueType"  placeholder="请输入switch选择器(建议用字段名的英文)"/>
			</div>
			
		</a-modal>
	</div>
</template>

<script>
	export default{
		
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
		},
		watch:{
			mydatas:{
				handler(newName, oldName){
					this.$emit('input',this.mydatas);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.mydatas){
						this.mydatas = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data(){
			return {
				mydatas:null,
				showEdit:false,
			}
		},
		created(){
			this.mydatas = this.value;
		},
		methods:{
			showAct(){
				this.showEdit = true;
			},
			handleCancel(){
				this.showEdit = false;
			},
		}
	}
</script>

<style>
	.banner-edit-table{
		background: #F5F6F8;
		width: 100%;
	}
	.banner-edit-table thead th{
		padding: 10px 20px;
		font-size: 14px;
		color:#666666;
	}
	.banner-edit-table tbody td{
		padding: 10px 20px;
	}
	.banner-edit-table tbody tr{
		border-top: 1px solid #E4E6ED;
	}
</style>