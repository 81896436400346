<template>
	<a-form-model-item  :label="datas.valueName" >
		<a-textarea   :placeholder="datas.parameter.placeholder" :rows="datas.parameter.autosize"></a-textarea>
	</a-form-model-item>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return{
				loading:false,
				calldata:null,
			}
		},
		created(){
			
		},
		
		methods:{
			
		}
	}
</script>

<style>
	
	
</style>