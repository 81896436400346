<template> 
	<div>
		<table cellpadding="0" cellspacing="0" class="plugins-table">
			
			<tbody>
				<tr v-for="(item,index) in myPlugins" :key="index">
					<td>
						{{myPlugins[index].name}}
					</td>
					
					<td class="flex alcenter end">
						<a-button type="link" @click="upAct(index)" v-if="index > 0" icon="arrow-up"></a-button>
						<a-button type="link" @click="downAct(index)" v-if="(index < myPlugins.length - 1 ) && myPlugins.length >1" icon="arrow-down"></a-button>
						<a-button type="link" @click="deleteAct(index)" icon="delete" style="color:#F76260"></a-button>
						
						<edit-input v-if="item.key == 'input'" v-model="myPlugins[index]"></edit-input>
						<edit-textarea v-if="item.key == 'textarea'" v-model="myPlugins[index]"></edit-textarea>
						<edit-radio v-if="item.key == 'radio'" v-model="myPlugins[index]"></edit-radio>
						<edit-checkbox v-if="item.key == 'checkbox'" v-model="myPlugins[index]"></edit-checkbox>
						<edit-select v-if="item.key == 'select'" v-model="myPlugins[index]"></edit-select>
						<edit-switch v-if="item.key == 'switch'" v-model="myPlugins[index]"></edit-switch>
					</td>
				</tr>
			</tbody>
		</table>
		<div  class="flex center mt16">
			<a-button @click="addPlugins" type="link" icon="plus">新增组件</a-button>
		</div>
		
		<a-modal
			  width="480px"
		      title="添加组件"
		      :visible="showPlugins"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="flex wrap space">
				<div v-for="(item,index) in tempPlugins" :key="index" :class="{mt16:index > 1}" class="plugin-item">
					<span class="ft14 ftw600 cl-main">
						{{item.name}}
					</span>
					<div @click="addAct(index)" class="select-icon">
						<i  class="iconfont iconadd_big  ft12"></i>
					</div>
				</div>
			</div>
		</a-modal>
		
	</div>
</template>

<script>
	import {plugins} from './template.js';
	import editInput from './setting/input.vue';
	import editTextarea from './setting/textarea.vue';
	import editRadio from './setting/radio.vue';
	import editCheckbox from './setting/checkbox.vue';
	import editSelect from './setting/select.vue';
	import editSwitch from './setting/switch.vue';
	export default{
		components:{
			editInput,
			editTextarea,
			editRadio,
			editCheckbox,
			editSelect,
			editSwitch,
		},
		props:{
			value:{
				type:Array,
				default:function(){
					return new Array;
				}
			}
		},
		watch:{
			myPlugins:{
				handler(newName, oldName){
					this.$emit('input',this.myPlugins);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.myPlugins){
						this.myPlugins = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data(){
			return {
				myPlugins:[],
				showPlugins:false,
				tempPlugins:[],
			}
		},
		created(){
			this.myPlugins = this.value;
			this.tempPlugins = plugins;
		},
		methods:{
			addPlugins(){
				this.showPlugins = true;
			},
			handleCancel(){
				this.showPlugins = false;
			},
			addAct(index){
				let plugins = JSON.parse(JSON.stringify(this.tempPlugins));
				let data = plugins[index];
				this.myPlugins.push(data);
				this.showPlugins = false;
			},
			deleteAct(index){
				this.myPlugins.splice(index,1);
			},
			downAct(index){
				let myPlugins = JSON.parse(JSON.stringify(this.myPlugins));
				let down = parseInt(index) + 1;
				let my = myPlugins[index];
				let d  = myPlugins[down];
				myPlugins[index] =  d;
				myPlugins[down]  = my;
				this.myPlugins = myPlugins;
			},
			upAct(index){
				let myPlugins = JSON.parse(JSON.stringify(this.myPlugins));
				let up = parseInt(index) - 1;
				let my = myPlugins[index];
				let u = myPlugins[up];
				myPlugins[index] =  u;
				myPlugins[up]  = my;
				this.myPlugins = myPlugins;
			},
		}
	}
</script>

<style>
	.plugins-table{
		background: #FFFFFF;
		width: 100%;
	}
	.plugin-item{
		width: 200px;
		height: 50px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border-radius: 4px;
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	.plugin-item .select-icon{
		width: 48px;
		height: 32px;
		background: #F5F6FA;
		border-radius: 16px;
		text-align: center;
		line-height: 32px;
		color:#AEB2C1;
	}
	.plugins-table tbody td{
		padding: 10px 20px;
	}
	.plugins-table tbody tr{
		border-bottom: 1px solid #E4E6ED;
	}
	.plugins-table tbody tr:last-child{
		border:none;
	}
</style>