<template>
	<div >
		<a-spin :spinning="loading">
			<div class="ft20 cl-main ftw600">工单时间设置</div>
			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" v-bind="formItemLayoutWithOutLabel">
						<a-form-model-item label="可预约时间" required>
							<div class="flex wrap alcenter">
								<template v-for="(item,index) in weeks">
									<div class="flex alcenter cl-main mr20 mb20 clickAct"  @click="removeWeek(index)" v-if="form.week.indexOf(item.id) != -1">
										<div class="week-choose">
											<i class="iconfont iconduihao cl-w ft12"/>
										</div>
										<div class="ml8 ft14 ftw400 cl-theme">{{item.name}}</div>
									</div>
									<div class="flex alcenter cl-main mr20 mb20 clickAct" @click="addWeek(index)" v-else>
										<div class="week-no-choose"></div>
										<div class="ml8 ft14 ftw400">{{item.name}}</div>
									</div>
								</template>
							</div>
						</a-form-model-item>
						<a-form-model-item label="节假日不可预约" required class="time-set">
							<a-form-model-item
									v-for="(domain, index) in form.domains"
									:key="domain.key"
									v-bind="index === 0 ? formItemLayout : {}"
									style="width: 70%;"
							>
								<a-range-picker
										style="width: 60%; margin-right: 8px"
										v-if="domain.value[1]"
										:value="[moment(domain.value[0], 'YYYY-MM-DD'), moment(domain.value[1], 'YYYY-MM-DD')]"
										:ranges="{ Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')] }"
										@change="(val,dateStrings)=>onChange(val,dateStrings,domain.key)"
								/>
								<a-range-picker
										style="width: 60%; margin-right: 8px"
										v-else
										@change="(val,dateStrings)=>onChange(val,dateStrings,domain.key)"
										:ranges="{ Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')] }"
								/>
								<a-icon
										v-if="form.domains.length > 1"
										class="dynamic-delete-button"
										type="minus-circle-o"
										:disabled="form.domains.length === 1"
										@click="removeDomain(domain)"
								/>
							</a-form-model-item>
							<a-form-model-item v-bind="formItemLayoutWithOutLabel">
								<a-button type="dashed" style="width: 60%" @click="addDomain">
									<a-icon type="plus"/>
									添加节假日时间段
								</a-button>
							</a-form-model-item>
						</a-form-model-item>
						<a-form-model-item label="预约起始时间" required v-if="!loading">
							<select-time-range v-model="form.appoint_time"></select-time-range>
						</a-form-model-item>

						<a-form-model-item label="预约时间间隔" required>
							<div class="flex alcenter wrap">
								<template v-for="(item,index) in appointment_intervals">
									<div class="appointment-interval-item active" v-if="item.interval==form.appointment_interval">{{item.info}}</div>
									<div class="appointment-interval-item" v-else @click="chooseAppointmentIntervalsAct(index)">{{item.info}}</div>
								</template>

							</div>
						</a-form-model-item>

						<a-form-model-item label="同时段预约数" required>
							<a-input-number :formatter="value => `${value} 人`" v-model="form.appointment_num" :min="0" :precision="0"
							 placeholder="请输入同时段预约数" style="width: 150px;"  />
							<span class="ft12 ml10 cl-notice">0表示不限制数量</span>
						</a-form-model-item>

						<a-form-model-item label="预约提前" required>
							<a-input-number :formatter="value => `${value} 小时`" v-model="form.appointment_advance" :min="0" :precision="0"
							  style="width: 150px;"  />
							<span class="ft12 ml10 cl-notice">0表示不限制预约时间</span>
						</a-form-model-item>


						<a-form-model-item label="休息公告" required>
							 <a-textarea placeholder="请输入公告内容" style="width: 600px;" :rows="2" v-model="form.rest_notice"/>
						</a-form-model-item>

						<a-form-model-item required label="是否配置补充字段">
							<a-radio-group v-model="form.is_setting_extra">
								<a-radio :value="0">不配置</a-radio>
								<a-radio :value="1">配置</a-radio>
							</a-radio-group>
						</a-form-model-item>

						<a-form-model-item label="预约扩充字段" required v-if="form.is_setting_extra==1">
							 <div>
								 <div>
								 		<a-alert message="请在右侧区域进行设置，左侧为展示区。" banner />
								 	</div>

								 	<div class="mt30">
								 		<div class="flex center">
								 			<div style="width: 50%;">
								 				<show-mobile :temps="form.extra" v-if="scope ==1"></show-mobile>
								 				<show-admin :temps="form.extra" v-if="scope ==2"></show-admin>
								 			</div>
								 			<div style="margin-left: 75px; width: 50%;">
								 				<div class="tempalte-operate1">


								 					<div class="mt16">
								 						<div class="ft14 ftwblod cl-balck"> 样式参考</div>
								 						<div class="flex alcenter mt12">
								 							<a-radio-group v-model="scope">
								 								<a-radio :value="1">
								 									小程序
								 								</a-radio>
								 								<a-radio :value="2">
								 									后管
								 								</a-radio>
								 							</a-radio-group>
								 						</div>
								 					</div>

								 					<div class="ft14 ftwblod cl-balck mt16">请编辑表单<span class="ft12 ml10 cl-notice">动态组件模版设置的时候只显示样式不调用真实数据</span></div>
								 					<div class="mt12" style="width: 100%;">
								 						<plugins-edit v-model="form.extra"></plugins-edit>
								 					</div>
								 				</div>
								 			</div>
								 		</div>
								 	</div>

							 </div>
						</a-form-model-item>

						<a-form-model-item :wrapper-col="{ span: 2, offset: 3 }" class="mt20">
							<a-button type="primary" @click="onSubmit" :loading="confirmLoading" v-bind="formItemLayoutWithOutLabel">
								保存
							</a-button>

						</a-form-model-item>
					</a-form-model>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import selectTimeRange from './components/order/modal/addAppointment/selectTimeRange.vue';
	import  pluginsEdit from './components/setting/plugins.vue';
	import showMobile from './components/setting/mobile.vue';
	import showAdmin from './components/setting/admin.vue';
	import moment from 'moment';
	export default{
		components:{
			selectTimeRange,
			pluginsEdit,
			showMobile,
			showAdmin,
			temps: [],
		},
		data(){
			return{
				dynamicValidateForm: {},
				formItemLayout: {
					labelCol: {
						xs: {span: 24},
						sm: {span: 4},
					},
					wrapperCol: {
						xs: {span: 24},
						sm: {span: 20},
					},
				},
				formItemLayoutWithOutLabel: {
					wrapperCol: {
						xs: {span: 24, offset: 0},
						sm: {span: 20, offset: 4},
					},
				},
				loading:false,
				confirmLoading:false,
				labelCol: { span: 3 },
				wrapperCol: { span: 21 },
				weeks:[
					{id:1,name:'周一'},
					{id:2,name:'周二'},
					{id:3,name:'周三'},
					{id:4,name:'周四'},
					{id:5,name:'周五'},
					{id:6,name:'周六'},
					{id:0,name:'周日'},
				],
				appointment_intervals:[
					{interval:30,info:'30分钟'},
					{interval:60,info:'1小时'},
					{interval:90,info:'90分钟'},
					{interval:120,info:'2小时'}
				],
				scope:1,
				form:{
					appoint_time:[],
					week:[],
					appointment_num:0,
					appointment_advance:0,
					appointment_interval:0,
					rest_notice:'',
					is_setting_extra:0,
					extra:[],
					domains: [],
				},
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			moment,
			onChange(dates, dateStrings, key) {
				console.log(dates);
				console.log(key)
				console.log(this.form.domains)
				this.form.domains.forEach(function (item, index) {
					if (item.key == key) {
						item.value = dateStrings;
						console.log(dateStrings)
					}

				})
			},
			removeDomain(item) {
				let index = this.form.domains.indexOf(item);
				if (index !== -1) {
					this.form.domains.splice(index, 1);
				}
			},
			addDomain() {
				this.form.domains.push({
					value: [],
					key: Date.now(),
				});
			},
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showAppointmentSetting').then(res=>{
					if(res.form){
						this.form=res.form;
						res.form.domains.forEach(function (item) {
							if(!item.value){
								item.value = [];
							}
							if (!item.key){
								item.value = Date.now();
							}
						})
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},


			/**
			 * 选中权限
			 * @param {Object} index
			 */
			addWeek(index) {
				if (this.form.week.indexOf(this.weeks[index].id) != -1) {
					this.$message.error("已有这个权限");
				}
				this.form.week.push(this.weeks[index].id);
				console.log(this.weeks);
			},

			/**
			 * 取消选择权限
			 * @param {Object} index
			 */
			removeWeek(index) {
				let startIndex = this.form.week.indexOf(this.weeks[index].id);
				if (startIndex == -1) {
					this.$message.error("已没有这个权限");
				}
				this.form.week.splice(startIndex, 1);
			},

			chooseAppointmentIntervalsAct(index){
				this.form.appointment_interval=this.appointment_intervals[index].interval;
			},

			onSubmit(){
				if(this.confirmLoading===true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveAppointmentSetting',{
					appoint_time:this.form.appoint_time,
					week:this.form.week,
					appointment_num:this.form.appointment_num,
					appointment_advance:this.form.appointment_advance,
					appointment_interval:this.form.appointment_interval,
					fictitious_take_count:this.form.fictitious_take_count,
					rest_notice:this.form.rest_notice,
					is_setting_extra:this.form.is_setting_extra,
					domains: this.form.domains,
					extra:JSON.stringify(this.form.extra),
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.loaddata();
					});
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
		}
	}
</script>

<style>
	.week-no-choose{
		width: 24px;
		height: 24px;
		border: 2px solid #A6AEC2;
	}
	.week-choose{
		width: 24px;
		height: 24px;
		background: #4772FF;
		line-height: 24px;
		text-align: center;
	}

	.appointment-interval-item{
		padding: 11px 20px;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		line-height: 1;
		font-size: 14px;
		font-weight: 500;
		color: #232529;
		margin-right: 10px;
		margin-bottom: 10px;
		cursor: pointer;
	}

	.appointment-interval-item.active{
		border: 1px solid #4772FF;
		color: #4772FF;
	}
	.tempalte-operate1 {
		width: 100%;
		height: 100%;
		background: #F9F9F9;
		border: 1px solid #F0F3F5;
		padding: 30px;
	}
</style>
