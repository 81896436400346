
export const plugins = [
	{
		name:'input输入框',
		key:'input',
		parameter:{
			placeholder:'请输入',
		},
		value:"",
		valueName:'',
		valueType:'',
		datas:[],
	},
	{
		name:'textarea文本输入框',
		key:'textarea',
		parameter:{
			placeholder:'请输入',
			autosize:4,
		},
		value:"",
		valueName:'',
		valueType:'',
		datas:[],
	},
	{
		name:'radio单选框',
		key:'radio',
		value:"",
		valueName:'',
		valueType:'',
		datas:[],
	},
	
	{
		name:'多选框',
		key:'checkbox',
		value:[],
		valueName:'',
		valueType:'',
		datas:[],
	},
	
	{
		name:'选择器',
		key:'select',
		parameter:{
			placeholder:'请输入',
		},
		value:"",
		valueName:'',
		valueType:'',
		datas:[],
	},
	
	{
		name:'开关',
		key:'switch',
		value:"",
		valueName:'',
		valueType:'',
		datas:[],
	},
	
];