<template>
	<div class="show-input-mobile-box  space">
		<div class="cl-info ft12">{{datas.valueName}}</div>
		<div class="cl-main ft14 mt10" :style="getHeight">{{datas.parameter.placeholder}}</div>
	</div>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return{
				loading:false,
				calldata:null,
			}
		},
		created(){
			
		},
		computed: {
			getHeight() {
				let num = this.datas.parameter.autosize;
				let height = num * 18
				
				return 'width:'+height + 'px;';
			}
		},
		methods:{
			
		}
	}
</script>

<style>
	.show-input-mobile-box{
		padding: 16px 15px;
	}
	.show-input-mobile-box-left{
		font-size: 12px;
		color: #8A8D99;
		width: 25%;
	}
	.show-input-mobile-box-right{
		font-size: 14px;
		color: #232529;
		/* width: 65%; */
	}
	
</style>